import { FeatureFlag } from '@app/enums';
import { Injectable } from '@angular/core';
import { Platform } from '@app/classes/platform.class';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

const ReportingModuleMenu: MenuItem[] = [
    {
        key: 'reporting.tabs.general',
        link: ['/reporting'],
        routerLinkExact: true,
    },
    {
        key: 'reporting.tabs.payroll',
        link: ['/reporting', 'payroll'],
        module: Platform.modules.payroll,
        can: ['accessPayroll', 'accessReporting'],
        checkAllPermissions: true,
    },
    {
        key: 'reporting.tabs.dataImport',
        link: ['/reporting', 'import'],
        hideIfHasFeatureFlag: FeatureFlag.timeOffImprovements,
    },
    {
        key: 'reporting.tabs.dataExport',
        link: ['/reporting', 'export'],
        can: ['create.file', 'export.employee'],
        checkAllPermissions: true,
    },
    {
        key: 'reporting.tabs.customReports',
        link: ['/reporting', 'custom-reports'],
    },
];

@Injectable()
export class ReportingModuleMenuService extends MenuService {
    setBaseMenu(): void {
        const showPayroll = this.auth.company.isPayrollSyncEnabled;
        if (!showPayroll) {
            this.baseMenu = ReportingModuleMenu.filter(({ key }) => key !== 'reporting.tabs.payroll');
            return;
        }
        this.baseMenu = ReportingModuleMenu;
    }
}
